<template lang="">
    <div>
        <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/gestaoMedica/request">
            <template #content>
                <div class="card p-fluid w-full">
                    <Steps :model="items" :readonly="true"> </Steps>
                    <router-view
                        v-slot="{ Component }"
                    >
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </div>
            </template>
        </AppPanel>
    </div>
</template>
<script>
import { getCurrentCompany } from '@/services/store';
import ConvocacaoService from './services/ConvocacaoService';
import EnumEtapaConvocacao from '@/enums/EnumEtapaConvocacao'

export default {
    data() {
        return {
            formObject: {},
            $service: null
        };
    },
    mounted() {
        this.$service = new ConvocacaoService();
        this.load();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar convocação' : 'Adicionar convocação';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        recordId() {
            return this.$route.params.id;
        },
        items() {
            return [
                {
                    label: 'Parâmetros',
                    to: '/convocacao-exames/parametros'
                },
                {
                    label: 'Processando convocação',
                    to: `/convocacao-exames/${this.recordId}/processando-convocacao`
                },
                {
                    label: 'Funcionários',
                    to: `/convocacao-exames/${this.recordId}/funcionarios`
                },
                {
                    label: 'Confirmação Telefone/E-mail',
                    to: `/convocacao-exames/${this.recordId}/confirmacao`
                },
                {
                    label: 'Processando solicitação',
                    to: `/convocacao-exames/${this.recordId}/processando-solicitacao`
                },
                {
                    label: 'Concluído',
                    to: `/convocacao-exames/${this.recordId}/concluido`
                }
            ]
        }
    },
    methods: {
        async load() {
            const response = await this.$service.findPendente();
            this.form = response.data?.[0] || {};
            this.redirecionarAbaCorreta();
        },
        redirecionarAbaCorreta() {
            switch (this.form?.etapa) {
                case EnumEtapaConvocacao.CRIADA:
                    this.$router.push('/convocacao-exames/parametros');
                    break;
                case EnumEtapaConvocacao.BUSCANDO_FUNCIONARIOS:
                    this.$router.push(`/convocacao-exames/${this.form.id}/processando-convocacao`);
                    break;
                case EnumEtapaConvocacao.FUNCIONARIOS:
                    this.$router.push(`/convocacao-exames/${this.form.id}/funcionarios`);
                    break;
                case EnumEtapaConvocacao.CONFIRMACAO:
                    this.$router.push(`/convocacao-exames/${this.form.id}/confirmacao`);
                    break;
                case EnumEtapaConvocacao.SOLICITANDO:
                    this.$router.push(`/convocacao-exames/${this.form.id}/processando-solicitacao`);
                    break;
                case EnumEtapaConvocacao.FINALIZADA:
                    this.$router.push(`/convocacao-exames/${this.form.id}/concluido`);
                    break;
                default:
                    this.$router.push('/convocacao-exames/parametros');
                    break;
            }
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
